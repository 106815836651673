import { IncomingMessage } from "http";
import tailwindConfig from "../tailwind.config.js";
import resolveConfig from "tailwindcss/resolveConfig";

export const getTailwindBreakpoints = (size: "sm" | "md" | "lg" | "xl") => {
  /*
    in tailwind.config.js:
    sm: "480px",
    md: "768px",
    lg: "976px",
    xl: "1440px",
    */
  const cssConfig = resolveConfig(tailwindConfig) as any;
  return parseInt(cssConfig?.theme?.screens?.[size].slice(0, -2));
};

export const isClient = typeof window === "object";

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getServerBaseUrl(req: IncomingMessage) {
  const protocol = req?.headers["x-forwarded-proto"] || "http";
  const host = req.headers.host;
  return `${protocol}://${host}`;
}
