import { firebaseAnalytics } from "../lib/firebase/firebase";
import {
  segmentUserAuthenticatedAnalytics,
  segmentUserUnauthenticatedAnalytics,
} from "../lib/segment/segment";
import { logEvent } from "firebase/analytics";
import UAParser from "ua-parser-js";
import { parseAuthJWT } from "../lib/auth0/auth";
import { sendFacebookPixelEvent, sendTikTokPixelEvent } from "./pixel";
import { v4 as uuidv4 } from "uuid";

/* 
    no integration with web firebase and segment
    so analytics are set up according to
    https://developers.google.com/tag-platform/gtagjs/reference/events
    in an attempt to mimic the events in both firebase and segment
*/

type FirebaseItem = {
  item_id: string;
  item_name: string;
  [x: string]: any;
};

export const resetSegment = () => {
  segmentUserAuthenticatedAnalytics.reset();
  segmentUserUnauthenticatedAnalytics.reset();
};

export const subscribeToNewsletterAnalytics = (email: string) => {
  segmentUserUnauthenticatedAnalytics.track("newsletter_signup", {
    email: email,
  });
};

export type ProductCartEventProperties = {
  cart_id: string;
  product_id: string;
  sku: string;
  name: string;
  price: string;
  quantity: number;
  coupon?: string;
  url: string;
  image_url: string;
};

const isAuthenticated = (): boolean => {
  return parseAuthJWT("id") !== undefined;
};

const getSegmentAnalytics = () => {
  return isAuthenticated()
    ? segmentUserAuthenticatedAnalytics
    : segmentUserUnauthenticatedAnalytics;
};

export const segmentIdentify = ({
  id,
  ...properties
}: {
  id: string;
} & Record<string, string>) => {
  segmentUserAuthenticatedAnalytics.identify(id, properties);
};

const globalEventProperties = (): { [key: string]: any } => {
  const urlParams = new URLSearchParams(window.location.search);
  const userAgentParser = new UAParser();
  let properties: { [key: string]: any } = {
    source: "Website",
    browser: userAgentParser.getBrowser().name,
    browser_version: userAgentParser.getBrowser().version,
    os: userAgentParser.getOS().name,
    ef_tid: getEverflowAdverstiserTransactionId(),
  };

  [
    "campaign_name",
    "campaign_source",
    "campaign_medium",
    "campaign_term",
    "campaign_content",
    "tid",
  ].forEach((key) => {
    if (urlParams.get(key)) {
      properties[key] = urlParams.get(key);
    }
  });

  return properties;
};

const globalContextProperties = (): { [key: string]: any } => {
  if (typeof window === "undefined") return {};
  const urlParams = new URLSearchParams(window.location.search);
  let context: { [key: string]: any } = {};

  ["utm_ad_id", "utm_adset_id", "utm_ad_name", "utm_adset_name"].forEach(
    (key) => {
      if (urlParams.get(key)) {
        const keyWithoutUtm = key.replace("utm_", "");
        context[keyWithoutUtm] = urlParams.get(key);
      }
    }
  );

  return context;
};

export const sendExceptionAnalytics = (description: string) => {
  logEvent(firebaseAnalytics, "exception", {
    description: description,
  });
  segmentUserAuthenticatedAnalytics.track("login", {
    description: description,
  });
};

export const sendSignupAnalytics = () => {
  logEvent(firebaseAnalytics, "sign_up", {
    method: "auth0",
  });
  segmentUserAuthenticatedAnalytics.track("sign_up", {
    method: "auth0",
  });
};

export const sendLoginAnalytics = (email: string) => {
  logEvent(firebaseAnalytics, "login", {
    method: "auth0",
    email: email,
  });
  segmentUserAuthenticatedAnalytics.track("login", {
    method: "auth0",
    email: email,
  });
};

export const addToCartAnalytics = (
  value: number,
  currency: string, //currency code
  items: FirebaseItem[],
  eventProperties?: ProductCartEventProperties
) => {
  const eventId = uuidv4();
  logEvent(firebaseAnalytics, "add_to_cart", {
    value,
    currency,
    items,
  });
  if (isAuthenticated()) {
    segmentUserAuthenticatedAnalytics.track(
      "add_to_cart",
      {
        value,
        currency,
        items,
      },
      {
        ...globalContextProperties(),
      }
    );
  } else {
    segmentUserUnauthenticatedAnalytics.track(
      "Product Added",
      {
        ...eventProperties,
        ...globalEventProperties(),
      },
      {
        ...globalContextProperties(),
      }
    );
  }
  const properties = globalEventProperties();
  const user_agent = `${properties.browser} ${properties.browser_version}`;
  sendTikTokPixelEvent("AddToCart", {
    event_id: eventId,
    value,
    currency,
    content_id: eventProperties?.product_id,
    content_type: "product",
    content_name: eventProperties?.name,
    timestamp: new Date().toISOString(),
    user_agent,
  });
};

export const removeFromCartAnalytics = (
  value: number,
  currency: string, //currency code
  items: FirebaseItem[],
  eventProperties: ProductCartEventProperties
) => {
  logEvent(firebaseAnalytics, "remove_from_cart", {
    value,
    currency,
    items,
  });
  if (isAuthenticated()) {
    segmentUserAuthenticatedAnalytics.track(
      "remove_from_cart",
      {
        value,
        currency,
        items,
      },
      {
        ...globalContextProperties(),
      }
    );
  } else {
    segmentUserUnauthenticatedAnalytics.track(
      "Product Removed",
      {
        ...eventProperties,
        ...globalEventProperties(),
      },
      {
        ...globalContextProperties(),
      }
    );
  }
};

export const pageViewAnalytics = (pageLocation: string, pageName: string) => {
  const eventId = uuidv4();
  logEvent(firebaseAnalytics, "page_view", {
    page_location: pageLocation,
  });
  getSegmentAnalytics().page(undefined, pageName, globalEventProperties(), {
    ...globalContextProperties(),
  });
  const properties = globalEventProperties();
  const user_agent = `${properties.browser} ${properties.browser_version}`;
  sendTikTokPixelEvent("ViewContent", {
    event_id: eventId,
    user_agent,
    timestamp: new Date().toISOString(),
  });
};

export const everflowPageViewAnalytics = () => {
  segmentUserUnauthenticatedAnalytics.track(
    "Page View",
    {
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const qrCodeAnalytics = (
  utm_source: string | string[],
  utm_medium: string | string[],
  utm_campaign: string | string[]
) => {
  logEvent(firebaseAnalytics, "s1_qr_code_scanned", {
    utm_source,
    utm_medium,
    utm_campaign,
  });
  getSegmentAnalytics().track("s1_qr_code_scanned", {
    utm_source,
    utm_medium,
    utm_campaign,
  });
};

export const systemDropDownInteractedAnalytics = (
  destination: string,
  title: string
) => {
  getSegmentAnalytics().track(
    "System Dropdown Interacted",
    {
      ...globalEventProperties(),
      destination_url: destination,
      page_title: title,
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const unpackDnaSectionClickedAnalytics = () => {
  getSegmentAnalytics().track(
    "Unpack DNA Section Clicked",
    globalEventProperties(),
    {
      ...globalContextProperties(),
    }
  );
};

export const productModuleInteractedAnalytics = (
  interactionType: string,
  moduleTitle: string,
  image_url: string
) => {
  getSegmentAnalytics().track(
    "Product Module Interacted",
    {
      interaction_type: interactionType,
      interaction_timestamp: new Date().toISOString(),
      module_name: moduleTitle,
      image_url: image_url,
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const productViewedAnalytics = (productName: string) => {
  getSegmentAnalytics().track(
    "Product Viewed",
    {
      product_name: productName,
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const buyDNAKitClickedAnalytics = (buttonLocation: string) => {
  getSegmentAnalytics().track(
    "Buy DNA Kit Button Clicked",
    {
      location: buttonLocation,
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const privacyAndSecurityDropdownInteractedAnalytics = (
  sectionTitle: string,
  opened: boolean
) => {
  getSegmentAnalytics().track(
    "Privacy & Security Dropdown Interacted",
    {
      dropdown_title: sectionTitle,
      action: opened ? "Expanded" : "Collapsed",
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const productDetailsExpandedAnalytics = (
  sectionTitle: string,
  productName: string,
  productId: string
) => {
  getSegmentAnalytics().track(
    "Product Details Expanded",
    {
      details_name: sectionTitle,
      product_name: productName,
      product_id: productId,
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const imageCarouselInteractedAnalytics = (interactionType: string) => {
  getSegmentAnalytics().track(
    "Image Carousel Interacted",
    {
      interaction_type: interactionType,
      ...globalEventProperties(),
    },
    {
      ...globalContextProperties(),
    }
  );
};

export const ageVerificationClickedAnalytics = () => {
  getSegmentAnalytics().track(
    "Age Verification Clicked",
    globalEventProperties(),
    {
      ...globalContextProperties(),
    }
  );
};

export const leadQuizInitiatedAnalytics = () => {
  segmentUserUnauthenticatedAnalytics.track(
    "Lead Quiz Initiated",
    globalEventProperties(),
    {
      ...globalContextProperties(),
    }
  );
};

export const leadQuizStartedAnalytics = () => {
  segmentUserUnauthenticatedAnalytics.track(
    "Lead Quiz Age Selected",
    globalEventProperties(),
    {
      ...globalContextProperties(),
    }
  );
};

export type DemoQuizAnswersT = {
  age_range: string;
  fitness_goal: string;
  weight_loss_goal: string;
  timeframe: string;
  current_weight: string;
  gender: string;
  hours_slept_each_night: string;
  anxiety_frequency: string;
  chronic_inflammation: boolean;
  burning_fat_or_maintaing_weight: string;
  burning_fat_or_maintaing_weight_specify?: string;
  burning_carbs: string;
  burning_carbs_specify?: string;
  preferred_exercise: string;
  first_name: string;
  last_name: string;
  email: string;
};

export const leadQuizCompletedAnalytics = (answers: DemoQuizAnswersT) => {
  const eventId = uuidv4();
  segmentUserUnauthenticatedAnalytics.track(
    "Lead Quiz Completed",
    {
      ...globalEventProperties(),
      ...answers,
      ...attachGclid(),
      facebookEventId: eventId,
    },
    {
      ...globalContextProperties(),
    }
  );
  sendFacebookPixelEvent(
    "Lead Quiz Completed",
    {
      em: answers.email.trim().toLowerCase(),
      fn: answers.first_name.trim().toLowerCase(),
      ln: answers.last_name.trim().toLowerCase(),
      ...((answers.gender === "Man" || answers.gender === "Woman") && {
        ge: answers.gender[0].toLowerCase() === "m" ? "m" : "f",
      }),
    },
    eventId
  );
  const properties = globalEventProperties();
  const user_agent = `${properties.browser} ${properties.browser_version}`;
  sendTikTokPixelEvent("LeadQuizCompleted", {
    event_id: eventId,
    timestamp: new Date().toISOString(),
    email: answers.email.trim().toLowerCase(),
    user_agent: user_agent,
  });
};

export const attachGclid = () => {
  const gclidJSON = localStorage.getItem("gclid");
  if (!gclidJSON) {
    return undefined;
  }
  const gclid = JSON.parse(gclidJSON);
  const gclidIsValid = new Date().getTime() < gclid.expiryDate;
  if (!gclidIsValid) {
    return undefined;
  }
  return {
    gclid: gclid.value,
  };
};

export const viewBagAnalytics = () => {
  segmentUserUnauthenticatedAnalytics.track(
    "View Bag",
    globalEventProperties(),
    {
      ...globalContextProperties(),
    }
  );
};

export const checkedIsGiftAnalytics = () => {
  segmentUserUnauthenticatedAnalytics.track(
    "DNA Gift",
    globalEventProperties(),
    {
      ...globalContextProperties(),
    }
  );
};

export const getEverflowAdverstiserTransactionId = () => {
  if (
    typeof window !== "undefined" &&
    window.EF &&
    typeof window.EF.getAdvertiserTransactionId === "function"
  ) {
    return window.EF.getAdvertiserTransactionId(1);
  } else {
    return null;
  }
};
